<template>
  <base-page class="preferences">
    <base-title :level="1">
      Voorkeuren
    </base-title>
    <base-paragraph>
      Hier kan je straks je voorkeuren instellen.
    </base-paragraph>
  </base-page>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
